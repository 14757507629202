<template>
	<section class="course__intro" v-if="stage === 0">
		<v-container>
			<v-row>
				<v-col>
					<h1 class="course__intro--title">
						{{ activeCourseTitle }}
					</h1>
					<p
						v-if="activeCourseDescription"
						class="course__intro--description"
					>
						{{ activeCourseDescription }}
					</p>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<StudentIntroImage
						v-if="activeCourseIntroImage"
						:file="activeCourseIntroImage"
					></StudentIntroImage>
					<section class="course__intro--blocks">
						<StudentBlockTextBlock
							v-if="introParagraph.block.paragraphs"
							:paragraphs="introParagraph.block.paragraphs"
						/>
						<StudentBlockBulletList
							v-if="introBulletList.block.header"
							:header="introBulletList.block.header"
							:headerLevel="2"
							:items="introBulletList.block.items"
						/></section
				></v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-btn
						class="w-100 mt-10 admin-primary-button primary-contrast-background"
						@click="startCourse()"
						>Start Course</v-btn
					>
				</v-col>
			</v-row>
		</v-container>
	</section>

	<section v-else class="course__content">
		<component
			v-for="blockEl in activeCourse"
			:key="blockEl.id"
			:is="setComponent(blockEl)"
			:content="blockEl.block.content"
			:header="blockEl.block.header"
			:nodes="blockEl.block.nodes"
			:headerLevel="blockEl.block.head_level"
			:elements="blockEl.block.elements"
			:items="blockEl.block.items"
			:question="blockEl.block.question"
			:ordered="blockEl.block.ordered"
			:options="blockEl.block.options"
			:sourcesNodes="blockEl.block.source_nodes"
			:targetNodes="blockEl.block.target_nodes"
			:paragraphs="blockEl.block.paragraphs"
			:file="blockEl.block.file"
			:id="blockEl.block.id"
		></component>
	</section>
</template>

<script>
import CourseEditor from "./CourseEditor.vue";
import StudentBlockBulletList from "../../blocks/StudentBlockBulletList.vue";
import StudentBlockComparison from "../../blocks/StudentBlockComparison.vue";
import StudentBlockConnect from "../../blocks/StudentBlockConnect.vue";
import StudentBlockHeadline from "../../blocks/StudentBlockHeadline.vue";
import StudentBlockImage from "../../blocks/StudentBlockImage.vue";
import StudentBlockMultipleChoices from "../../blocks/StudentBlockMultipleChoices.vue";
import StudentBlockTextBlock from "../../blocks/StudentBlockTextBlock.vue";
import StudentBlockTrueFalse from "../../blocks/StudentBlockTrueFalse.vue";
import StudentBlockVideo from "../../blocks/StudentBlockVideo.vue";
import StudentIntroImage from "../../blocks/StudentIntroImage.vue";
import { studentBlock } from "@/util/widgets/studentBlock";

export default {
	name: "CourseContent",
	props: {
		course: {
			type: Object,
			require: true
		},
		stage: {
			type: Number,
			require: true
		}
	},
	components: {
		CourseEditor,
		StudentBlockBulletList,
		StudentBlockComparison,
		StudentBlockConnect,
		StudentBlockHeadline,
		StudentBlockImage,
		StudentBlockMultipleChoices,
		StudentBlockTrueFalse,
		StudentBlockTextBlock,
		StudentBlockVideo,
		StudentIntroImage
	},
	computed: {
		courseId() {
			return this.course?.id;
		},
		chapterId() {
			return Number(this.$route.query.chapter);
		},
		activeCourseTitle() {
			return this.course?.title;
		},
		activeCourseDescription() {
			return this.course?.description;
		},
		activeCourseIntroImage() {
			return this.course?.images[0]?.image;
		},
		activePageName() {
			return this.page?.title;
		},
		activeChapter() {
			if (!!this.course?.chapters === false) return;
			return this.course?.chapters.find(
				el => el.id === Number(this.$route.query.chapter)
			);
		},
		activePage() {
			if (!!this.activeChapter === false) return;
			return this.activeChapter.pages.find(
				el => el.id === Number(this.$route.query.page)
			);
		},
		activeCourse() {
			return this.activePage?.blocks;
		},
		introBulletList() {
			if (!!this.course === false)
				return { block: { header: "", head_level: 2, items: [] } };
			return this.course?.intro_blocks.find(el => el.blk_type_id === 7);
		},
		introParagraph() {
			if (
				!!this.course === false ||
				this.course?.intro_blocks.find(el => el.blk_type_id === 9)
			)
				return { block: { paragraphs: [] } };
			return this.course?.intro_blocks.find(el => el.blk_type_id === 9);
		}
	},

	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			pageContent: this.page?.content,
			newPageTitle: "",
			correctComponent: ""
		};
	},
	methods: {
		setComponent(blk) {
			return studentBlock[Number(blk.blk_type_id)];
		},
		async cancelEditting() {
			this.setIsEditing(false);
			this.newPageTitle = "";
		},
		setIsEditing(val) {
			this.isEditing = val;
		},
		startCourse() {
			console.log("test");

			this.$emit("setValue", { key: "stage", value: 1 });
			this.$router.push({
				query: {
					...this.$route.query,
					chapter: this.course.chapters[0].id,
					page: this.course.chapters[0].pages[0].id
				}
			});
			localStorage.setItem(
				`courseData[${this.$route.query.course}]`,
				JSON.stringify({
					chapterId: this.course.chapters[0].id,
					pageId: this.course.chapters[0].pages[0].id
				})
			);
		}
	}
};
</script>

<style lang="scss" scoped></style>
