export const studentBlock = {
	1: "StudentBlockMultipleChoices",
	2: "StudentBlockConnect",
	4: "StudentBlockHeadline",
	6: "StudentBlockImage",
	7: "StudentBlockBulletList",
	8: "StudentBlockTrueFalse",
	9: "StudentBlockTextBlock",
	10: "StudentBlockVideo",
	11: "StudentBlockComparison",
	12: "StudentBlockBulletList",
	13: "StudentBlockTextBlock"
};
