<template>
	<article class="course-editor-block-container">
		<h2 class="course-editor-block-header">{{ content }}</h2>
		<section class="d-flex flex-row justify-space-between">
			<figure
				v-for="node in nodes"
				class="d-flex flex-column"
				:key="node.id"
			>
				<img :src="node.file.public_url" alt="" />
				<p>{{ node.content }}</p>
			</figure>
		</section>
	</article>
</template>

<script>
export default {
	name: "StudentBlockComparison",
	props: ["content", "nodes"]
};
</script>

<style lang="scss" scoped></style>
