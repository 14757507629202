<template>
	<article class="course__block multiple-choices">
		<h2>Question #{{ id }}</h2>
		<p>{{ question }}</p>
		<v-checkbox
			v-model="selectedAnswer.option"
			v-for="(option, index) in options"
			:key="option.id"
			:label="option.content"
		>
			{{ option.content }}
		</v-checkbox>
	</article>
</template>

<script>
export default {
	name: "StudentBlockMultipleChoices",
	props: {
		id: {
			type: Number,
			require: true
		},
		question: {
			type: String,
			require: true
		},
		options: {
			type: Array,
			require: true
		}
	},
	computed: {},
	data() {
		return {
			selectedAnswer: null
		};
	}
};
</script>

<style lang="scss" scoped></style>
