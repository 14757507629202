<template>
	<article class="course__block headline">
		<figure v-html="htmlContent"></figure>
	</article>
</template>

<script>
export default {
	name: "StudentBlockHeadline",
	props: {
		header: {
			type: String,
			require: true
		},
		headerLevel: {
			type: String,
			require: true
		}
	},
	computed: {
		htmlContent() {
			return `<h${this.headerLevel}>${this.header}</h${this.headerLevel}>`;
		}
	},
	data() {
		return {};
	}
};
</script>

<style lang="scss" scoped></style>
