<template>
	<article
		class="course__block intro-image"
		:style="`background-image: url(${publicUrl})`"
	></article>
</template>

<script>
export default {
	name: "StudentIntroImage",
	props: {
		file: {
			type: Object,
			require: true
		}
	},
	computed: {
		publicUrl() {
			return this.file?.public_url;
		}
	}
};
</script>
