<template>
	<article class="course__block image">
		<img :src="file.public_url" :alt="file.comment" />
	</article>
</template>

<script>
export default {
	name: "StudentBlockImage",
	props: {
		file: {
			type: Object,
			require: true
		}
	},
	computed: {},
	data() {
		return {};
	}
};
</script>

<style lang="scss" scoped></style>
