<template>
	<article class="course__block text-block">
		<p v-for="paragraph in paragraphs" :key="paragraph.key">
			{{ paragraph.content }}
		</p>
	</article>
</template>

<script>
export default {
	name: "StudentBlockTextBlock",
	props: {
		paragraphs: {
			type: Array,
			require: true
		}
	}
};
</script>

<style lang="scss" scoped></style>
