<template>
	<article class="course__block bullet-list">
		<p v-html="htmlContent"></p>
		<ul>
			<li v-for="element in items" :key="element.id">
				{{ element.content }}
			</li>
		</ul>
	</article>
</template>

<script>
export default {
	name: "StudentBlockBulletList",
	props: {
		header: {
			type: String,
			require: true
		},
		headerLevel: {
			type: Number,
			require: false
		},
		items: {
			type: Array,
			require: true
		}
	},
	computed: {
		htmlContent() {
			return `<h${this.headerLevel}>${this.header}</h${this.headerLevel}>`;
		}
	}
};
</script>

<style lang="scss" scoped></style>
