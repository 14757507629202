<template>
	<section class="course-editor-content__chapters-container--widget">
		<v-row>
			<v-col cols="8">
				<h4>Block: {{ blockTitle }}</h4>
			</v-col>
			<v-col cols="4"
				><div class="d-flex flex-row justify-end">
					<span
						><v-icon
							@click="
								$emit('changeBlkIndex', { mark: -1, block })
							"
							>mdi-chevron-up</v-icon
						></span
					>
					<span
						><v-icon
							@click="$emit('changeBlkIndex', { mark: 1, block })"
							>mdi-chevron-down</v-icon
						></span
					>
					<span
						><v-icon @click="$emit('editBlock', block)"
							>mdi-pencil-outline</v-icon
						></span
					>
					<span
						><v-icon
							@click="
								$emit('removeBlock', {
									blkType: block.blk_type_id,
									blkId: block.id
								})
							"
							>mdi-delete</v-icon
						></span
					>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<p><slot name="content"></slot></p>
			</v-col>
		</v-row>
	</section>
</template>

<script>
import { mapGetters } from "vuex";
import { titles } from "@/util/widgets/titles";

export default {
	name: "CourseEditor",
	props: {
		block: {
			type: Object,
			require: true
		}
	},
	computed: {
		...mapGetters(["isLoading"]),
		blockTitle() {
			return titles.widgets[this.block.blk_type_id];
		}
	},
	data() {
		return {
			content: this.value
		};
	},
	methods: {
		handleInput(event) {
			this.$emit("input", event);
		}
	}
};
</script>
